.header {
  margin-top: 2rem;
  margin-bottom: 3rem;
}

@media screen and (min-height: 651px) {
  .header {
    margin-top: 2rem;
    margin-bottom: 4rem;
  }
}
