.background {
  font-size: calc(1rem / 2.5);
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 5.5em;
  height: 3.125em;
  border: 1px solid #bdc1cc;
  border-radius: 6.25em;
  position: relative;
  transition: background-color 0.2s;
}

.toggle {
  border: 1px solid #bdc1cc;
  content: "";
  position: absolute;
  left: 0.375em;
  width: 2.5em;
  max-width: 2.5em;
  height: 2.5em;
  border-radius: 2.5em;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.transform {
  left: calc(100% - (3.125em - 2.5em) / 2);
  transform: translateX(-100%);
  border-color: transparent;
}

.gradient {
  border-color: transparent;
  background-image: linear-gradient(
    243deg,
    #4cc9f0 0%,
    #6062d2 52%,
    #7209b7 100%
  );
  background-origin: border-box;
}
