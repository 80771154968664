.spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2em;
  height: 2em;
  margin-top: -1em;
  margin-left: -1em;
  border-radius: 50%;
  border-top: 2px solid currentColor;
  border-right: 2px solid transparent;
  animation: spin 0.5s linear infinite;
}

@keyframes spin {
  from {
    transform: none;
  }
  to {
    transform: rotate(360deg);
  }
}
