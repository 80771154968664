.primary {
  background-image: linear-gradient(
    243deg,
    #4cc9f0 0%,
    #6062d2 52%,
    #7209b7 100%
  );
  background-origin: border-box;
}

.primary:hover {
  background: #fff;
  color: #b5179e;
  border: 1px solid #cbceeb;
}
