.placeholderStyle::placeholder {
  color: #5f657a;
  font-style: italic;
  opacity: 1;
}

.inputStyle:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #020214 inset !important;
  -webkit-text-fill-color: #c1c5da;
}
