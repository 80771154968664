.animate {
  animation: pop 0.5s;
}

@keyframes pop {
  0% {
    transform: none;
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: none;
  }
}
